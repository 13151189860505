import React, { useState, useEffect } from 'react';
import axios from "axios";
import { BsFillTrashFill, BsFillSave2Fill } from "react-icons/bs";
import { BiSolidHide, BiShow } from "react-icons/bi";
import { FaCheck } from "react-icons/fa";
import InputNotes from '../../forms/formsNotes/InputNotes';

//css
import styles from "../../../css/css_views/Finance.module.css";

function FinanceCard({ finance, setFinances, addTransaction, balanceCss }) {
    const [updatedNote, setUpdatedNote] = useState({ ...finance, type: 'minus' });
    const [transactionName, setTransactionName] = useState('');
    const [transactionAmount, setTransactionAmount] = useState('');
    const [balance, setBalance] = useState(0);
    const [containerVisible, setContainerVisible] = useState(true);
    const [titleChanged, setTitleChanged] = useState(false);
    const [expense, setExpense] = useState('');
    const [income, setIncome] = useState('');
    const [markedTransactions, setMarkedTransactions] = useState([]);

    useEffect(() => {
        const total = getTotal(finance.transactions.map(transaction => transaction.amount));
        setBalance(total);
    }, [finance.transactions]);

    useEffect(() => {
        const totalExpense = getExpense(finance.transactions.map(transaction => transaction.amount));
        setExpense(totalExpense);
    }, [finance.transactions]);

    useEffect(() => {
        const totalIncome = getIncome(finance.transactions.map(transaction => transaction.amount));
        setIncome(totalIncome);
    }, [finance.transactions]);

    useEffect(() => {
        const localStorageMarkedTransactions = JSON.parse(localStorage.getItem(`markedTransactions_${finance.id}`)) || [];
        setMarkedTransactions(localStorageMarkedTransactions);
    }, [finance.id]);

    useEffect(() => {
        const localStorageVisibility = localStorage.getItem(`containerVisible_${finance.id}`);
        if (localStorageVisibility !== null) {
            setContainerVisible(localStorageVisibility === 'true');
        }
    }, [finance.id]);

    const handleAddTransaction = (e) => {
        e.preventDefault();
        const type = updatedNote.type;
        const amount = type === 'minus' ? -Math.abs(transactionAmount) : Math.abs(transactionAmount);
        addTransaction(finance, transactionName, amount);
        setTransactionName('');
        setTransactionAmount('');
        window.location.reload();
    };

    const handleTitleChange = (e) => {
        setUpdatedNote({ ...updatedNote, title: e.target.value });
        setTitleChanged(e.target.value !== finance.title);
    };

    const saveTitle = () => {
        if (titleChanged) {
            setFinances(prevFinances => {
                const updatedFinances = prevFinances.map(fin => {
                    if (fin.id === finance.id) {
                        return { ...fin, title: updatedNote.title };
                    }
                    return fin;
                });
                localStorage.setItem('finances', JSON.stringify(updatedFinances));
                return updatedFinances;
            });
        }
        window.location.reload();
    };

    const toggleTransactionMark = (id) => {
        if (markedTransactions.includes(id)) {
            setMarkedTransactions(markedTransactions.filter(mark => mark !== id));
        } else {
            setMarkedTransactions([...markedTransactions, id]);
        }
    };

    const okTransaction = (id) => {
        toggleTransactionMark(id);
        const updatedTransactions = finance.transactions.map(transaction => {
            if (transaction.id === id) {
                return { ...transaction, ok: true };
            }
            return transaction;
        });
        setFinances(prevFinances => {
            const updatedFinances = prevFinances.map(fin => {
                if (fin.id === finance.id) {
                    return { ...fin, transactions: updatedTransactions };
                }
                return fin;
            });
            localStorage.setItem('finances', JSON.stringify(updatedFinances));
            return updatedFinances;
        });
        const total = getTotal(updatedTransactions.map(transaction => transaction.amount));
        setBalance(total);
        const localStorageMarkedTransactions = JSON.parse(localStorage.getItem(`markedTransactions_${finance.id}`)) || [];
        if (localStorageMarkedTransactions.includes(id)) {
            localStorage.setItem(`markedTransactions_${finance.id}`, JSON.stringify(localStorageMarkedTransactions.filter(mark => mark !== id)));
        } else {
            localStorage.setItem(`markedTransactions_${finance.id}`, JSON.stringify([...localStorageMarkedTransactions, id]));
        }
        window.location.reload();
    };

    const removeTransaction = (id) => {
        const updatedTransactions = finance.transactions.filter(transaction => transaction.id !== id);
        const updatedFinances = { ...finance, transactions: updatedTransactions };
        setFinances(updatedFinances);
        removeTransactionFromLocalStorage(id);
        const total = getTotal(updatedTransactions.map(transaction => transaction.amount));
        setBalance(total);
        window.location.reload();
    };

    const removeFinance = () => {
        const financesFromStorage = JSON.parse(localStorage.getItem('finances') || '[]');
        const updatedFinances = financesFromStorage.filter(item => item.id !== finance.id);
        localStorage.setItem('finances', JSON.stringify(updatedFinances));
        setFinances(updatedFinances);
        const total = getTotal(updatedFinances.flatMap(item => item.transactions.map(transaction => transaction.amount)));
        setBalance(total);
        window.location.reload();
    };

    const removeTransactionFromLocalStorage = (id) => {
        const financesFromStorage = JSON.parse(localStorage.getItem('finances') || '[]');
        const index = financesFromStorage.findIndex(finance => finance.transactions.some(transaction => transaction.id === id));
        if (index !== -1) {
            const updatedFinances = [...financesFromStorage];
            updatedFinances[index].transactions = updatedFinances[index].transactions.filter(transaction => transaction.id !== id);
            localStorage.setItem('finances', JSON.stringify(updatedFinances));
            window.location.reload();
        }
    };

    const getExpense = transactionsAmounts => Math.abs(transactionsAmounts
        .filter(value => value < 0)
        .reduce((accumulator, value) => accumulator + value, 0))
        .toFixed(2);

    const getIncome = transactionsAmounts => transactionsAmounts
        .filter(value => value > 0)
        .reduce((accumulator, value) => accumulator + value, 0)
        .toFixed(2);

    const getTotal = transactionsAmounts => transactionsAmounts
        .reduce((accumulator, transaction) => accumulator + transaction, 0)
        .toFixed(2);

    const toggleContainerVisibility = () => {
        const newValue = !containerVisible;
        setContainerVisible(newValue);
        localStorage.setItem(`containerVisible_${finance.id}`, newValue.toString());
    };
   
    // let balanceBarra;

    // if(expense === '' && income === ''){
    //     balanceBarra = 100
    // }else{
    //     balanceBarra =(100-(expense*100)/ (income));
    // }
    const balanceBarra = (expense === 0 && income === 0) ? 100 : 100 - (expense * 100) / income;

    return (
        <div className={styles.container}>
            <div className={styles.container_children}>
                <div className={styles.hide}>
                    <button onClick={toggleContainerVisibility}>
                        {containerVisible ? <BiSolidHide /> : <BiShow />}
                    </button>
                </div>
                <div className={styles.note_finance_title}>
                    <InputNotes
                        type="text"
                        name="title"
                        placeholder="Deseja inserir algum titulo?"
                        handleOnChange={handleTitleChange}
                        value={updatedNote.title ? updatedNote.title : ''}
                    />
                    {titleChanged && (
                        <div className={styles.finance_card_actions}>
                            <button onClick={saveTitle}>
                                <BsFillSave2Fill />Salvar
                            </button>
                        </div>
                    )}
                </div>
                
                {containerVisible && (
                    <>
                        <div className={styles.container_balance}>
                            <div className={styles.inc_exp_container}>
                                <div className={styles.balance_saldo}>
                                    <h4>Saldo atual</h4>
                                    <h1 id="balance" className={balance < 0 ? styles.balance_minus : styles.balance_plus}>R$ {balance}</h1>
                                    <div className={balanceBarra > 51 ? styles.barra_good : balanceBarra > 16 ? styles.barra_attention : styles.barra_careful}>

                                    </div>
                                </div>
                                <div className={styles.balance_plus_minus}>
                                    <div>
                                        <h4>Receitas</h4>
                                        <p id="money-plus" className={styles.money_plus}>
                                            + R$ {income}
                                        </p>
                                    </div>
                                    <div>
                                        <h4>Despesas</h4>
                                        <p id="money-minus" className={styles.money_minus}>
                                            - R$ {expense}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h3>Transações</h3>
                        <div id="transactions" className={styles.transactions}>
                            <ul>
                                {finance.transactions && finance.transactions.map((transaction) => (
                                    <li key={transaction.id} className={`${transaction.amount < 0 ? styles.minus : styles.plus} ${markedTransactions.includes(transaction.id) ? styles.marked : ''}`}>
                                        <div className={styles.list}>
                                            {transaction.name}<span>{transaction.amount < 0 ? '- R$' : '+ R$'} {Math.abs(transaction.amount)}</span>
                                        </div>
                                        <div className={styles.transactions_btn}>
                                            <button onClick={() => okTransaction(transaction.id)}>
                                                <FaCheck />
                                            </button>
                                            <button onClick={() => removeTransaction(transaction.id)}>
                                                <BsFillTrashFill />
                                            </button>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className={styles.add_transaction}>
                            <h3>Adicionar transação</h3>
                            <form id="form">
                                <div className={styles.form_control}>
                                    <input autoFocus type="text" id="textInput" value={transactionName} onChange={(e) => setTransactionName(e.target.value)} placeholder="Insira um nome" />
                                </div>
                                <div className={styles.form_control}>
                                    <input type="number" id="amountInput" step="0.01" value={transactionAmount} onChange={(e) => setTransactionAmount(e.target.value)} placeholder="Insira um valor" />
                                </div>
                                <div className={styles.type_search}>
                                    <div className={styles.type_search_minus}>
                                        <input
                                            type="radio"
                                            name={`searchType_${finance.id}`}
                                            id={`radio_minus_${finance.id}`}
                                            value="minus"
                                            checked={updatedNote.type === 'minus'}
                                            onChange={() => setUpdatedNote({ ...updatedNote, type: 'minus' })}
                                        />
                                        <label htmlFor={`radio_minus_${finance.id}`}>Despesas</label>
                                    </div>
                                    <div className={styles.type_search_plus}>
                                        <input
                                            type="radio"
                                            name={`searchType_${finance.id}`}
                                            id={`radio_plus_${finance.id}`}
                                            value="plus"
                                            checked={updatedNote.type === 'plus'}
                                            onChange={() => setUpdatedNote({ ...updatedNote, type: 'plus' })}
                                        />
                                        <label htmlFor={`radio_plus_${finance.id}`}>Receita</label>
                                    </div>
                                </div>
                                <div className={styles.finance_card_actions}>
                                    <button onClick={handleAddTransaction}>
                                        <BsFillSave2Fill />Salvar
                                    </button>
                                    <button onClick={removeFinance}>
                                        <BsFillTrashFill />Excluir
                                    </button>
                                </div>
                            </form>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}

export default FinanceCard;