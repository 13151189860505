
import ContactUser from '../layout/ContactUser';

//css
import styles from '../../css/css_views/Home.module.css';


function Contact(){
    return(
        <section className={styles.home_container}>
                <ContactUser/>
         </section>

    )
}

export default Contact;