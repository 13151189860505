import React, { useState, useEffect } from "react";
import { BsFillTrashFill, BsFillSave2Fill } from "react-icons/bs";
import { BiSolidHide, BiShow } from "react-icons/bi";
import { FaCheck } from "react-icons/fa";
import InputNotes from "../../forms/formsNotes/InputNotes";
import { v4 as uuid } from 'uuid';

import styles from "../../../css/css_project/Notes.module.css";

function NotesCard({ note, setNotes, addTransaction }) {
    const [updatedNote, setUpdatedNote] = useState({ ...note });
    const [transactionName, setTransactionName] = useState('');
    const [containerVisible, setContainerVisible] = useState(true);
    const [titleChanged, setTitleChanged] = useState(false);
    const [markedTransactions, setMarkedTransactions] = useState([]);

    useEffect(() => {
        const localStorageMarkedTransactions = JSON.parse(localStorage.getItem(`markedTransactions_${note.id}`)) || [];
        setMarkedTransactions(localStorageMarkedTransactions);
    }, [note.id]);

    useEffect(() => {
        const localStorageVisibility = localStorage.getItem(`containerVisible_${note.id}`);
        if (localStorageVisibility !== null) {
            setContainerVisible(localStorageVisibility === 'true');
        }
    }, [note.id]);

    const handleAddTransaction = (e) => {
        e.preventDefault();

        if (!transactionName.trim()) return;

        const newTransaction = { id: uuid(), name: transactionName };
        const updatedTransactions = [...note.transactions, newTransaction];
        const updatedNote = { ...note, transactions: updatedTransactions };

        setNotes(prevNotes => {
            const updatedNotes = prevNotes.map(n => (n.id === note.id ? updatedNote : n));
            localStorage.setItem('notes', JSON.stringify(updatedNotes));
            return updatedNotes;
        });

        setTransactionName('');
    };

    const handleTitleChange = (e) => {
        setUpdatedNote({ ...updatedNote, title: e.target.value });
        setTitleChanged(e.target.value !== note.title);
    };

    const saveTitle = () => {
        if (titleChanged) {
            setNotes(prevNotes => {
                const updatedNotes = prevNotes.map(n => {
                    if (n.id === note.id) {
                        return { ...n, title: updatedNote.title };
                    }
                    return n;
                });
                localStorage.setItem('notes', JSON.stringify(updatedNotes));
                return updatedNotes;
            });
        }
        window.location.reload();
    };

    const toggleTransactionMark = (id) => {
        if (markedTransactions.includes(id)) {
            setMarkedTransactions(markedTransactions.filter(mark => mark !== id));
        } else {
            setMarkedTransactions([...markedTransactions, id]);
        }
        localStorage.setItem(`markedTransactions_${note.id}`, JSON.stringify(markedTransactions));
    };

    const okTransaction = (id) => {
        toggleTransactionMark(id);
        const updatedTransactions = note.transactions.map(transaction => {
            if (transaction.id === id) {
                return { ...transaction, ok: true };
            }
            return transaction;
        });
        setNotes(prevNotes => {
            const updatedNotes = prevNotes.map(n => {
                if (n.id === note.id) {
                    return { ...n, transactions: updatedTransactions };
                }
                return n;
            });
            localStorage.setItem('notes', JSON.stringify(updatedNotes));
            return updatedNotes;
        });
    };

    const removeTransaction = (id) => {
        const updatedTransactions = note.transactions.filter(transaction => transaction.id !== id);
        const updatedNote = { ...note, transactions: updatedTransactions };
        setNotes(prevNotes => {
            const updatedNotes = prevNotes.map(n => (n.id === note.id ? updatedNote : n));
            localStorage.setItem('notes', JSON.stringify(updatedNotes));
            return updatedNotes;
        });
    };

    const removeNote = () => {
        const notesFromStorage = JSON.parse(localStorage.getItem('notes') || '[]');
        const updatedNotes = notesFromStorage.filter(item => item.id !== note.id);
        localStorage.setItem('notes', JSON.stringify(updatedNotes));
        setNotes(updatedNotes);
    };

    const toggleContainerVisibility = () => {
        const newValue = !containerVisible;
        setContainerVisible(newValue);
        localStorage.setItem(`containerVisible_${note.id}`, newValue.toString());
    };

    return (
        <div className={styles.container_notes}>
            <div className={styles.container_children}>
                <div className={styles.hide}>
                    <button onClick={toggleContainerVisibility}>
                        {containerVisible ? <BiSolidHide /> : <BiShow />}
                    </button>
                </div>
                <div className={styles.note_title}>
                    <InputNotes
                        type="text"
                        name="title"
                        placeholder="Deseja inserir algum titulo?"
                        handleOnChange={handleTitleChange}
                        value={updatedNote.title ? updatedNote.title : ''}
                    />
                    {titleChanged && (
                        <div className={styles.note_card_actions}>
                            <button onClick={saveTitle}>
                                <BsFillSave2Fill />Salvar
                            </button>
                        </div>
                    )}
                </div>
                {containerVisible && (
                    <>
                        <h3>Anotações</h3>
                        <div id="transactions" className={styles.add_note}>
                            <ul>
                                {note.transactions && note.transactions.map((transaction) => (
                                    <li key={transaction.id} className={markedTransactions.includes(transaction.id) ? styles.marked : ''}>
                                        <div className={styles.list}>
                                            <span>{transaction.name}</span>
                                            <div className={styles.transactions_btn}>
                                                <button onClick={() => okTransaction(transaction.id)}>
                                                    <FaCheck />
                                                </button>
                                                <button onClick={() => removeTransaction(transaction.id)}>
                                                    <BsFillTrashFill />
                                                </button>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className={styles.add_transaction}>
                            <h3>Adicionar anotação</h3>
                            <form id="form" onSubmit={handleAddTransaction}>
                                <div className={styles.form_control}>
                                    <input autoFocus type="text" id="textInput" value={transactionName} onChange={(e) => setTransactionName(e.target.value)} placeholder="Insira uma anotação" />
                                </div>
                                <div className={styles.noteTask_card_actions}>
                                    <button type="submit">
                                        <BsFillSave2Fill />Adicionar
                                    </button>
                                    <button type="button" onClick={removeNote}>
                                        <BsFillTrashFill />Excluir
                                    </button>
                                </div>
                            </form>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}

export default NotesCard;