import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectFade } from 'swiper';

//css
import styles from "../../../css/css_carousel/Carousel.module.css";
import { Link } from 'react-router-dom';
// import 'swiper/swiper.min.css';
// import 'swiper/components/effect-fade/effect-fade.min.css';

function Carousel({ images, slidePreview}) {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [showNavigation, setShowNavigation] = useState(true); // Define se a navegação deve ser exibida


  useEffect(() => {
      function handleResize() {
          if (window.innerWidth < 720) {
              setCurrentSlide(0);
          }
      }

      handleResize();
      window.addEventListener("resize", handleResize);

      return () => {
          window.removeEventListener("resize", handleResize);
      }
  }, []);

  const handleSlideChange = (swiper) => {
      setCurrentSlide(swiper.activeIndex);
  }

  return (
    
      <Swiper
      
          effect="fade"
          slidesPerView={slidePreview}
          pagination={{ clickable: true }}
          navigation={window.innerWidth > 451} // Define se a navegação deve ser exibida
          autoplay={{ delay: 6000 }}
          onSlideChange={(swiper) => handleSlideChange(swiper)}
      >
          {images.map((item, index) => (
              <SwiperSlide key={item.id}>
                <div className={styles.container}>
                  <img src={item.image} alt="slider" style={{ width: '100%' }} className={styles.container_img}/>
                        <div className={styles.home_banner}>
                          {index === currentSlide && (
                            <>
                              <h1>{item.texth1}</h1>
                              <Link to={`${item.rota}`} className={styles.linkWithoutDecoration}><h2>{item.texth2}</h2></Link>
                              <h3>{item.texth3}</h3>
                              <span>{item.textSpan}<p>{item.textP}</p></span>
                            </>
                          )}
                        </div>
                </div>
              </SwiperSlide>
          ))}
      </Swiper>
  );
}

export default Carousel;