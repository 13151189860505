import styles from "../../../css/css_project/Notes.module.css";

function TextareaNotes({handleOnChange, placeholder, value}){

    return(
        <div className={styles.note_notes}>
            <textarea 
                onChange={(e) => handleOnChange(e)}
                placeholder={placeholder} 
                value={value}
            />
        </div>
    );

};

export default TextareaNotes;