//css
import styles from '../../css/css_views/Home.module.css';

function Register(){
    return(
        <div>
            <section className={styles.home_container}>
                <h1>Cadastro</h1>
            </section>
        </div>
    )
}
export default Register;