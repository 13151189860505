import { useState, useEffect } from "react";
import { FaSearch } from "react-icons/fa";
import { BsFilePlus } from "react-icons/bs";

import InputSearchFinances from "../forms/formsNotes/InputSearchNotes";
import { v4 as uuid } from 'uuid'; // importando a função uuid
import Container from "./Container";
import FinanceCard from "../project/finances/FinanceCard";

//css
import styles from "../../css/css_layout/Search.module.css";

function FinancesNotes() {
    
    const [title, setTitle] = useState('');
    const [amount, setAmount] = useState('');
    const [finances, setFinances] = useState([]);
    const [searchResult, setSearchResult] = useState([]);

    useEffect(() => {
        const storedFinances = JSON.parse(localStorage.getItem('finances') || '[]');
        setFinances(storedFinances);
    }, []);

    useEffect(() => {
        if (amount) {
            const filteredFinances = finances.filter(finance =>
                finance.title.toLowerCase().includes(amount.toLowerCase()) 
            );
            setSearchResult(filteredFinances);
        } else {
            setSearchResult(finances); // Renderizar todas as finanças se o campo de busca estiver vazio
        }
    }, [amount, finances]);

    function createNoteFinance() {
        const finance = {
            id: uuid(),
            title: title,
            content: amount,
            transactions: []
        };
        const updatedFinances = [...finances, finance];
        setFinances(updatedFinances);
        localStorage.setItem('finances', JSON.stringify(updatedFinances));
        setTitle('');
        setAmount('');
    }

    function handleChange(e) {
        setTitle(e.target.value);
    }

    function handleChangeSearchNote(e) {
        setAmount(e.target.value.toLowerCase());
    }

    function addTransaction(note, transactionName, transactionAmount) {
        if (!transactionName || !transactionAmount) {
            alert("Preencha os campos");
            return;
        }
        const newTransaction = {
            id: uuid(),
            name: transactionName,
            amount: Number(transactionAmount)
        };
        const updatedNote = { ...note };
        updatedNote.transactions.push(newTransaction);
        const noteIndex = finances.findIndex(fin => fin.id === note.id);
        const updatedFinances = [...finances];
        updatedFinances[noteIndex] = updatedNote;
        setFinances(updatedFinances);
        localStorage.setItem('finances', JSON.stringify(updatedFinances));
    }

    return (
        <div>
            <div className={styles.container_top}>
                <div>
                    <div className={styles.search_container}>
                        <button className={styles.add_note}><FaSearch /></button>
                        <InputSearchFinances
                            type="text"
                            value={amount}
                            name="searchNote"
                            handleOnChange={handleChangeSearchNote}
                            placeholder="Busque por uma finança"
                        />
                         {/* <InputSearchFinances
                            type="text"
                            value={title}
                            onChange={handleChange}
                            placeholder="Adicione um nome para sua finança"
                        /> */}
                        <button className={styles.add_note} onClick={createNoteFinance}><BsFilePlus /></button>
                    </div>                    
                </div>
            </div>

            <div className={styles.container_mb}>
                <div className={styles.container_top_mb}>
                    <button className={styles.add_note} ><FaSearch /></button>
                        <InputSearchFinances
                            type="text"
                            value={amount}
                            name="searchNote"
                            handleOnChange={handleChangeSearchNote}
                            placeholder="Busque uma Finança"
                        />
                        <button className={styles.add_note} onClick={createNoteFinance}><BsFilePlus /></button>
                </div>
            </div>
            
            <div>
                <Container customClass="container">
                    {Array.isArray(searchResult) && searchResult.map(finance => (
                        <FinanceCard key={finance.id} finance={finance} setFinances={setFinances} addTransaction={addTransaction} />
                    ))}
                </Container>
            </div>
        </div>
    );
}

export default FinancesNotes;