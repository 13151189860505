//componets

//layout

import SearchNotes from '../layout/SearchNotes';


//css


function Notes() {
    return (
        <section>
            <SearchNotes/>
            
       </section>
    );
}

export default Notes;