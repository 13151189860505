import {useState, useEffect} from "react";

import { FaSearch } from "react-icons/fa";
import { BsFilePlus } from "react-icons/bs";

import InputSearchNotes from "../forms/formsNotes/InputSearchNotes";

import { v4 as uuid } from 'uuid'; // importando a função uuid
import Container from "./Container";
import NoteCard from "../project/NoteCard";

//css
import styles from "../../css/css_layout/Search.module.css";

function SearchNotes(){


    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');

    const [notes, setNotes] = useState([]); // Estado para armazenar as notas filtradas
    const [searchResult, setSearchResult] = useState([]);


    useEffect(() => {
        const storedNotes = JSON.parse(localStorage.getItem('notes') || '[]');
        setNotes(storedNotes);
    }, []);

    function createNote() {
        const note = {
            id: uuid(), // gerando o ID único
            title: title,
            content: content
        };
    
        const existingNoteIndex = notes.findIndex(existingNote => existingNote.title === title && existingNote.content === content);
        
        if (existingNoteIndex !== -1) {
            // Se for uma nota existente, atualize-a no array de notas
            const updatedNotes = [...notes];
            updatedNotes[existingNoteIndex] = { ...note, id: updatedNotes[existingNoteIndex].id }; // Mantenha o mesmo ID
            setNotes(updatedNotes);
            localStorage.setItem('notes', JSON.stringify(updatedNotes));
        } else {
            // Se for uma nova nota, adicione-a ao array de notas
            const updatedNotes = [...notes, note];
            setNotes(updatedNotes);
            localStorage.setItem('notes', JSON.stringify(updatedNotes));
        }
    
        // Limpar os campos após salvar
        setTitle('');
        setContent('');

       //window.location.reload()
    }

    function handleChange(e) {        
        setTitle(e.target.value)
    }

    function handleChangeSearchNote(e) {
        setContent(e.target.value.toLowerCase());
    }
   
    useEffect(() => {
        const filteredNotes = notes.filter(note =>
            note.title.toLowerCase().includes(content) ||
            note.content.toLowerCase().includes(content)
        );
        setSearchResult(filteredNotes);
    }, [content, notes]);

    return (
        <div>
            <div className={styles.container_top}>
                <div>
                    <div className={styles.search_container}>
                        <button className={styles.add_note} ><FaSearch /></button>
                        <InputSearchNotes
                            type="text"
                            value={content}
                            name="searchNote"
                            handleOnChange={handleChangeSearchNote}
                            placeholder="Busque por uma nota"
                        />

                        <InputSearchNotes
                            type="text"
                            value={title}
                            name="addNote"
                            handleOnChange={handleChange}
                            placeholder="O que deseja lembrar"
                        />
                        <button className={styles.add_note} onClick={createNote}><BsFilePlus /></button>
                    </div>
                    <div className={styles.type_search}>
                        <input 
                            type="radio" 
                            name="searchType" 
                            id="radio" 
                            value="title" 
                            // checked={searchType === 'title'} 
                        
                        />
                        <label>Titulo</label>
                        <input 
                            type="radio" 
                            name="searchType" 
                            id="radio" 
                            value="content" 
                            // checked={searchType === 'content'}
                        /> 
                        <label>Conteúdo</label>               
                    </div>
                </div>
            </div>
            <div className={styles.container_mb}>
		
                <div className={styles.container_top_mb}>
                    
                    <div className={styles.display_search}>
                        <div className={styles.search_container}>
                            <button className={styles.add_note} ><FaSearch /></button>
                            <InputSearchNotes
                                type="text"
                                value={content}
                                name="searchNote"
                                handleOnChange={handleChangeSearchNote}
                                placeholder="<- Buscar Nota                     Criar Nota ->"
                            />
                            <button className={styles.add_note} onClick={createNote}><BsFilePlus /></button>
                        </div>
                        <div className={styles.type_search}>
                            <input type="radio" name="search" id="radio" value="title" checked/>
                            <label>Titulo</label>
                            <input type="radio" name="search" id="radio" value="content"/> 
                            <label>Conteúdo</label	>				
                        </div>
                    </div>
                </div>

            </div>

            <div>
                <Container customClass="container">
                {/* <ul>
                {searchResult.map((note, index) => (
                    <li key={index}>{note.title}</li>
                ))}
            </ul> */}
                {searchResult.map(note => (
                    <NoteCard key={note.id} note={note} setNotes={setNotes} notes={searchResult} />
                ))}

                </Container>
            </div>

        </div>
        
    );
}

export default SearchNotes;