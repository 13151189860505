import {BrowserRouter as Router, Routes, Route } from 'react-router-dom'


// ------------Views
import Home from './components/views/Home';
import Notes from './components/views/Notes';
import Task from './components/views/Task';
import Finance from './components/views/Finance';
import Register from './components/views/Register';
import PageNotFile from './components/views/PageNotFile';

// ------------Layouts
import Container from './components/layout/Container';
import Footer from './components/layout/Footer';
import NavBar from './components/layout/NavBar';
import Contact from './components/views/Contact';




function App() {
  return (
    <Router>
     
      <NavBar/>
      {/* "<Container/>" é o nosso componete pai e tudo que está dentro dele é passado atraves de "pros" como componete filho */}
      <Container customClass="min_height">
        <Routes>
          <Route exact path='/' element={<Home/>}/>
          <Route path='/notes' element={<Notes/>}/>
          <Route path='/task' element={<Task/>}/>
          <Route path='/finance'element={<Finance/>}/>
          <Route path='/contact'element={<Contact/>}/>
          {/* <Route path='/register'element={<Register/>}/> */}
          <Route path='*'element={<PageNotFile/>}/>

        </Routes>
      </Container>
      <Footer/>
    </Router>
  );
}

export default App;
