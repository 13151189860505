import {useState, useEffect} from 'react'

import styles from "../../css/css_layout/Message.module.css";

function Message({typeMsg, msg}){

    const [visible, setVisible] = useState(false)

    useEffect(() => {
        if(!msg){
            setVisible(false)
            return
        }

        setVisible(true)

        const timer = setTimeout(() => {
            setVisible(false)
        }, 2000)

        return () => clearTimeout(timer)
        

    }, [msg])

    return(

        <>
            {visible && (
                <div className={`${styles.message} ${styles[typeMsg]}`}>{msg}</div>
                
            )}
        </>

    )
}

export default Message;