import styles from "../../../css/css_project/Notes.module.css";

function InputNotes({type, text, name, placeholder, handleOnChange, value}){

    return(
        <div className={styles.note_title}>
            {/* <label htmlFor={name}>{text}:</label> */}
            <input 
                type={type} 
                name={name} 
                id={name} 
                placeholder={placeholder} 
                onChange={handleOnChange}
                value={value}
            />
        </div>
    )
}

export default InputNotes;