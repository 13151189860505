import {FaFacebook, FaInstagram, FaYoutube } from 'react-icons/fa'

import styles from "../../css/css_layout/Footer.module.css"


function Footer(){
    return(
        
        <footer className={styles.footer}>
            <ul className={styles.social_list}>
                <li><FaFacebook/></li>
                <li><FaInstagram/></li>
                <li><FaYoutube/></li>
            </ul>
            <p className={styles.copy_right}><span>ANOT</span> &copy; 2024</p>
        </footer>
    )
}

export default Footer;