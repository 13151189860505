//componets

//layout
import FinancesNotes from '../layout/FinancesNotes';

//css


function Notes() {
    return (
        <section>
            <FinancesNotes/>
       </section>
    );
}

export default Notes;