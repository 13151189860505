
import NotesTask from '../layout/NotesTask';


//css


function Task() {
    return (
        <section>
            <NotesTask/>
            
       </section>
    );
}

export default Task;