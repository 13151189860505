import React, { useEffect, useState } from 'react';


// import Carousel from '../banners/carousel/Carousel';
import styles from '../../css/css_views/Home.module.css';

import imageCarousel from  "../../img/img_carousel/mesa-organizada.jpg"
import imageCarousel02 from "../../img/img_carousel/bloco-de-anotações-sobre-a-mesa-c-a669.jpg"
import imageCarousel03 from "../../img/img_carousel/aprender financas.jpg"
import Carousel from '../banners/carousel/Carousel';

function Home(){

    const [slidePreview, setSlidePreview] = useState();

    const images = [
        {id:'1',rota:'/', image:imageCarousel, texth1:"A vida está corrida?", texth2:"Fique tranquilo!", texth3:"Te ajudamos com sua rotina.", textSpan:"|||ANOT ", textP:"Um jeito fácil de se organizar!"},
        {id:'2',rota:'/notes', image:imageCarousel02, texth1:"Guarde seu pensamento!", texth2:"Em Notas", texth3:"Você pode anotar e relembrar quando quiser.", textSpan:"|||ANOT ", textP:"Um jeito fácil de se organizar!"},
        {id:'3',rota:'/finance', image:imageCarousel03, texth1:"Organize suas Finanças!", texth2:"Em Finanças", texth3:"Você pode anotar suas finanças de forma simples!", textSpan:"|||ANOT ", textP:"Um jeito fácil de se organizar!"},
        // {id:'3', image:'https://sujeitoprogramador.com/wp-content/uploads/2022/03/Frame-321.png', texth1:"Organize suas Finanças!", texth2:"Em Finanças", texth3:"Você pode anotar suas finanças de forma simples!"},
    ]; 

    const handleResize = () => {
        if(window.innerWidth < 720){
            setSlidePreview(1)
        }else{
            setSlidePreview(2)
        }
    }

    window.addEventListener("resize", handleResize);

    return(
        <section className={styles.home_container}>
            <div>
                <Carousel images={images} slidePreview={slidePreview}/>
            </div>
        </section>
    )
}

export default Home;