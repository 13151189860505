import styles from "../../../css/css_general/cssGeneral.module.css"

function ButtonGeneral({text}){
    return(
        <div>
            <button className={styles.btn}>{text}</button>
        </div>
    )
}

export default ButtonGeneral;