
import styles from "../../../css/css_layout/Search.module.css";

function InputSearchNotes({type, text, name, placeholder, handleOnChange, handleChangeContent, value}){
    return(
        <div className={styles.search_container}>
            {/* <label htmlFor={name}>{text}:</label> */}
            <input className={styles.search_input}
                type={type} 
                name={name} 
                id={name} 
                placeholder={placeholder} 
                onChange={handleOnChange}
                value={value}
            />
    </div>
    )
};

export default InputSearchNotes;
