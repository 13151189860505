import styles from '../../css/css_layout/Container.module.css';

function Container(props){
    return(
        <div className={`${styles.container}} ${styles[props.customClass]}`}>
        {/* <div className={`${styles.container}} ${styles[props.customClass]}`}> */}
            {props.children}
            {/* com o "cildren" conseguimos inserir os componentes das paginas como componentes filhos */}
        </div>
    )
}

export default Container;