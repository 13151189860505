import { Link } from "react-router-dom";

import { MdAttachMoney } from "react-icons/md";
import { IoHomeOutline } from "react-icons/io5";
import { PiNotepad } from "react-icons/pi";
import { GrContact } from "react-icons/gr";

import Container from "./Container";

//css
import styles from "../../css/css_layout/NavBar.module.css";

function NavBar(){
    return(
        <nav className={styles.navbar}>
            <div className={styles.navbar_link}>
                <div className={styles.navbar_link_title}><a href="/">ANOT</a></div>
            </div>
            <div className={styles.container}>
                {/* <Container customClass="min_height"> */}
                    <ul className={styles.list}>
                        <li className={styles.item}><Link to="/">Home</Link></li>
                        {/* <li className={styles.item}><Link to="/notes">Notas</Link></li> */}
                        <li className={styles.item}><Link to="/task">Notas</Link></li>
                        <li className={styles.item}><Link to="/finance">Finanças   </Link></li>
                        {/* <li className={styles.item}><Link to="/contact">Contato</Link></li> */}
                        {/* <li className={styles.item}><Link to="/register">Cadastro</Link></li> */}
                    </ul>
                    
                {/* </Container> */}
            </div>
            <div className={styles.container_mb}>
                <ul className={styles.list_mb}>
                        <li className={styles.item_mb}><Link className={styles.item_mb_link} to="/"><IoHomeOutline /><span>Home</span></Link></li>
                        <li className={styles.item_mb}><Link className={styles.item_mb_link} to="/task"><PiNotepad /><span>Notas</span></Link></li>
                        {/* <li className={styles.item}><Link to="/task">Lembretes</Link></li> */}
                        <li className={styles.item_mb}><Link className={styles.item_mb_link} to="/finance"><MdAttachMoney /><span>Finanças</span></Link></li>
                        {/* <li className={styles.item_mb}><Link className={styles.item_mb_link} to="/contact"><GrContact /><span>Contato</span></Link></li> */}
                        {/* <li className={styles.item}><Link to="/register">Cadastro</Link></li> */}
                </ul>

            </div>
            {/* <a to="/"><span>ANOT</span></Link>  */}
            
        </nav>
    )
}

export default NavBar;