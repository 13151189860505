
//css
import styles from "../../css/css_erro/Erro.module.css"
import erro_404 from "../../img/erro_404.jpg"; 

function PageNotFile(){
    return(
        <div className={styles.container}>
                <h1>Ops, não lozalizamos a pagina!!!</h1>
                <img src={erro_404} alt="pagina não encontrada" />
        </div>
    )
}

export default PageNotFile;