import styles from "../../../css/css_general/cssGeneral.module.css";

function TextareaGeneral({name, text, handleOnChange, placeholder, value}){

    return(
        <div className={styles.general}>
            <label htmlFor={name}>{text}</label>
            <textarea 
                onChange={(e) => handleOnChange(e)}
                placeholder={placeholder} 
                value={value}
            />
        </div>
    );

};

export default TextareaGeneral;