import React, { useState, useEffect } from "react";
import { FaSearch } from "react-icons/fa";
import { BsFilePlus } from "react-icons/bs";
import InputSearchFinances from "../forms/formsNotes/InputSearchNotes";
import { v4 as uuid } from 'uuid'; // importando a função uuid
import Container from "./Container";
import NotesCards from "../project/notes/NotesCards"; // Certifique-se de que o nome do componente esteja correto
import styles from "../../css/css_layout/Search.module.css";

function NotesTask() {
    const [title, setTitle] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [notes, setNotes] = useState([]);
    const [searchResult, setSearchResult] = useState([]);


    useEffect(() => {
        const storedNotes = JSON.parse(localStorage.getItem('notes') || '[]');
        setNotes(storedNotes);
    }, []);

    useEffect(() => {
        if (searchTerm) {
            const filteredNotes = notes.filter(note =>
                note.title.toLowerCase().includes(searchTerm.toLowerCase()) 
            );
            setSearchResult(filteredNotes);
        } else {
            setSearchResult(notes); // Renderizar todas as notas se o campo de busca estiver vazio
        }
    }, [searchTerm, notes]);

    function createNote() {
        const note = {
            id: uuid(),
            title: title,
            transactions: []
        };
        const updatedNotes = [...notes, note];
        setNotes(updatedNotes);
        localStorage.setItem('notes', JSON.stringify(updatedNotes));
        setTitle('');
    }

    function handleChangeSearchTerm(e) {
        setSearchTerm(e.target.value.toLowerCase());
    }

    function addTransaction(note, transactionName) {
        if (!transactionName) {
            alert("Preencha o campo");
            return;
        }
        const newTransaction = {
            id: uuid(),
            name: transactionName
        };
        const updatedNote = { ...note };
        updatedNote.transactions.push(newTransaction);
        const noteIndex = notes.findIndex(n => n.id === note.id);
        const updatedNotes = [...notes];
        updatedNotes[noteIndex] = updatedNote;
        setNotes(updatedNotes);
        localStorage.setItem('notes', JSON.stringify(updatedNotes));
    }

    return (
        <div>
            <div className={styles.container_top}>
                <div>
                    <div className={styles.search_container}>
                        <button className={styles.add_note}><FaSearch /></button>
                        <InputSearchFinances
                            type="text"
                            value={searchTerm}
                            name="searchNote"
                            handleOnChange={handleChangeSearchTerm}
                            placeholder="Busque por uma nota"
                        />
                        <button className={styles.add_note} onClick={createNote}><BsFilePlus /></button>
                    </div>
                </div>
            </div>

            <div className={styles.container_mb}>
                <div className={styles.container_top_mb}>
                    <button className={styles.add_note}><FaSearch /></button>
                        <InputSearchFinances
                            type="text"
                            value={searchTerm}
                            name="searchNote"
                            handleOnChange={handleChangeSearchTerm}
                            placeholder="Busque por uma nota"
                        />
                    <button className={styles.add_note} onClick={createNote}><BsFilePlus /></button>
                </div>
            </div>
            
            <div>
                <Container customClass="container">
                    {Array.isArray(searchResult) && searchResult.map(note => (
                        <NotesCards key={note.id} note={note} setNotes={setNotes} addTransaction={addTransaction} />
                    ))}
                </Container>
            </div>

        </div>
    );
}

export default NotesTask;