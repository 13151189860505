import React, { useState } from "react";

import { BsPencil, BsFillTrashFill, BsFillSave2Fill } from "react-icons/bs";
import { Link } from "react-router-dom";

import styles from "../../css/css_project/Notes.module.css";
import InputNotes from "../forms/formsNotes/InputNotes";
import TextareaNotes from "../forms/formsNotes/TextareaNotes";
import Message from "../layout/Message";

function NoteCard({ note, setNotes, notes }) {

  const [updatedNote, setUpdatedNote] = useState({ ...note }); // Inicialize o estado com a nota atual
  const [noteMessage, setNoteMessage] = useState('');
  const [typeMessage, setTypeMessage] = useState('');
  const [isChanged, setIsChanged] = useState(false); // Verifica alteração no handle

  const remove = (id) => {
    const updatedNotes = notes.filter(item => item.id !== id);
    setNotes(updatedNotes);
    localStorage.setItem('notes', JSON.stringify(updatedNotes));
  };


  //Funcçao de atualizar
  function updateNote(id, newContent, newTitle){
    const notes = JSON.parse(localStorage.getItem("notes") || "[]");
    
    const targetNote = notes.find((note)=> note.id === id);

    if (targetNote) {
      targetNote.content = newContent;
      targetNote.title = newTitle;
      saveNote(notes);
    }

  }

  function saveNote (notes){
    setNoteMessage('');
    localStorage.setItem('notes', JSON.stringify(notes));

    if (isChanged) {
      setNoteMessage('Nota atualizada!!!');
      setTypeMessage('success');

      setTimeout(() => {
        window.location.href = 'https://www.anot.com.br/notes';
        window.location.reload();
      }, 2000);
    }

  };

  const handleTitleChange = (e) => {
    setUpdatedNote({ ...updatedNote, title: e.target.value });
    setIsChanged(true);
  };

  const handleContentChange = (e) => {
    setUpdatedNote({ ...updatedNote, content: e.target.value });
    setIsChanged(true);
  };

  const handleSaveNote = () => {
    updateNote(updatedNote.id, updatedNote.content, updatedNote.title);
  };

  return (
    <div className={styles.container_notes}>
      <InputNotes
        type="text" 
        name="title" 
        placeholder="Deseja inserir algum titulo?" 
        handleOnChange={handleTitleChange}//aqui envocamos a funcao handleChange que vai pegar o alvo do evento o nome do input "name="name" "
        value={updatedNote.title ? updatedNote.title: ''}
      />
      <div className={styles.note_notes}>
        <TextareaNotes                
          placeholder="Adicione algum texto"
          handleOnChange={handleContentChange}
          value={updatedNote.content ? updatedNote.content: ''}
        />
            {/* <textarea placeholder="Adicione algum texto" value={note?.content || ''} onChange={handleContentChange} /> */}
            
      <div className={styles.note_aba}>
              <div className={styles.note_card_actions}>
              <button onClick={handleSaveNote}>
                  <BsFillSave2Fill />Salvar
              </button>
                <button onClick={() => remove(note?.id)}>
                  <BsFillTrashFill />Excluir
                </button>
              </div>
            </div>
            {noteMessage && <Message typeMsg={typeMessage} msg={noteMessage}/>} 
          </div>
          
        </div>
      );
    }

export default NoteCard;