
import { useState } from "react";
import contact from "../../img/contact.jpg"
import ButtonGeneral from "../forms/formsGeral/ButtonGeneral";
import InputsGeneral from "../forms/formsGeral/InputsGeneral";
import TextareaGeneral from "../forms/formsGeral/TextareaGeneral";

//css
import style from "../../css/css_layout/Contact.module.css"

function ContactUser(){

    const [updatedNote, setUpdatedNote] = useState(''); // Inicialize o estado com a nota atual

    const submit = (e) =>{
        e.preventDefault()
        // console.log(project);

    }

    //Funcçao de atualizar
    function updateNote(id, name, email, text){
        const contaUser = JSON.parse(localStorage.getItem("notes") || "[]");
        
        const targetNote = contaUser.find((note)=> note.id === id);

        // if (targetNote) {
        // targetNote.content = newContent;
        // targetNote.title = newTitle;
        // saveNote(contaUser);
        // }

    }

    const handleContentChange = (e) => {
        setUpdatedNote({ ...updatedNote, content: e.target.value });
        // setIsChanged(true);
    };

    return(
        <div className={style.register_container}>
               
            <div className={style.container_banner_layer}>
                <div className={style.banner_layer}>
                    <div className={style.banner_layer_text}>
                        <h1>Explore.</h1>
                        <h1>Descubra.</h1>
                        <h1> Encante-se!</h1>
                        <h1>ANOT</h1>
                    </div>
                    {/* <img src={contact} alt="contado" /> */}
                </div>
             </div>
            <div className={style.register_form}>
                <h2>Deixe-nos saber como ajudar!</h2>
                <form method="post" onSubmit={submit} >
                    <div className={style.general}>
                        <InputsGeneral 
                            type="text" 
                            name="name"
                            id="name"
                            placeholder="Digite seu nome"
                            text="Nome"
                        />
                   </div>
                    <div className={style.general}>
                        <InputsGeneral 
                            type="email" 
                            name="email"
                            id="email"
                            placeholder="Digite seu email"
                            text="Email"
                        />
                    </div>
                    <div className={style.general}>
                        <TextareaGeneral
                            placeholder="Digite sua pergunta"
                            handleOnChange={handleContentChange}
                            value={''}
                            text="Como podemos ajudar?"
                        />
                    </div>
                    <div className={style.btn}>
                        <ButtonGeneral
                            text="Enviar"
                        />
                    </div>
                    {/* <input type="hidden" name="acao" value="enviar" /> */}
                    {/* <input type="submit" value="Cadastrar"/> */}
                </form>
        </div>
    </div>
    )
}

export default ContactUser;